import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Modal, Divider } from 'antd';
import { BsLightbulbFill, BsTrophyFill } from 'react-icons/bs';
import { MdPrint, MdSearch, MdAttachMoney, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import styles from './style.module.scss';
import { HiOutlineTicket } from 'react-icons/hi2';
import { IoCalendarOutline } from 'react-icons/io5';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ModalVerBoloesPrint = ({ apostas = [], nome, urlImpressao, regra, concurso, dataSorteio, valor, isModalVisible, closeModal, color, cotas, setModalVerApostas, quantidadeApostas, resultados, numerosSorteados }) => {
  console.log('color', color)
  const formatDate = (dateString) => {
    const options = { month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };
  const loteriaRegras = {
    lotofacil: [11, 12, 13, 14, 15],
    lotofacilindependencia: [11, 12, 13, 14, 15],
    lotomania: [0, 15, 16, 17, 18, 19, 20],
    duplasena: [3, 4, 5, 6],
    supersete: [3, 4, 5, 6, 7],
    megasena: [4, 5, 6],
    megasenadavirada: [4, 5, 6],
    quina: [2, 3, 4, 5],
    quinasaojoao: [2, 3, 4, 5],
    diadesorte: [],
    lotofacilfinalzero: [11, 12, 13, 14, 15],
    timemania: [3, 4, 5, 6, 7],
    diadesorte: [4, 5, 6, 7],
  };
  const hexToRGBA = (hex, opacity) => {
    if (!hex) return '';
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const formatarRegra = (regra) => {
    if (!regra) return '';
    const regraFormatada = regra
      .replace(/\[\[([^\]]+)\]\]/g, '<h2>$1</h2>')
      .replace(/\*\*\*/g, '<p>')
      .replace(/\*\*/g, '</p>')
      .replace(/\{\{/g, '<strong>')
      .replace(/\}\}/g, '</strong>')
      .replace(/\/\/\s*/g, '<br/>')
      .replace(/\|\|\s*/g, '<hr/>')
      .replace('QUANDO VOCÊ COMPARA, APOSTA COM A TRIBO', '')
      .replace('<h2>DESCRIÇÃO DA ESTRATÉGIA</h2>', '');

    const returnRegra = regraFormatada.split(/<h2>MATRIZ/);

    const primeiroTexto = returnRegra[0];
    let segundoTexto = '';
    for (let i = 1; i < returnRegra.length; i++) {
      segundoTexto += returnRegra[i];
    }

    return [primeiroTexto, segundoTexto];
  };

  const regrasFormatadas = formatarRegra(regra);

  const adjustColor = (color, amount) => {
    if (!color) {
      color = '#29705e';
    }
    let usePound = false;
    if (color[0] === '#') {
      color = color.slice(1);
      usePound = true;
    }

    let num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let b = ((num >> 8) & 0x00ff) + amount;
    let g = (num & 0x0000ff) + amount;

    r = Math.min(255, Math.max(0, r));
    b = Math.min(255, Math.max(0, b));
    g = Math.min(255, Math.max(0, g));

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  };

  const normalizeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '')
      .replace(/-/g, '')
      .toLowerCase();
  };

  const formatResultados = (resultados, tipoLoteria, dataSorteio) => {
    if (!dataSorteio || !tipoLoteria) return true;

    const currentDate = new Date();
    const sorteioDate = new Date(dataSorteio);
    const normalizedTipoLoteria = normalizeString(tipoLoteria);
    console.log('normalizedTipoLoteria', normalizedTipoLoteria)

    if (resultados && resultados.length === 0 && sorteioDate <= currentDate) {
      return loteriaRegras[normalizedTipoLoteria].map((acerto) => (
        <h1 className={styles.textoAcertos}>
          <p key={acerto}>
            <span>{acerto} Acertos </span>
            Nenhum
          </p>
        </h1>
      ));
    }

    return loteriaRegras[normalizedTipoLoteria]?.map((acerto) => {
      const item = resultados.find((res) => res.pontos === acerto);
      return (
        <h1 className={styles.textoAcertos}>
          <p key={acerto}>
            <span>{acerto} Acertos </span>
            {item ? `${item.jogos} jogos` : ' Nenhum'}
          </p>
        </h1>
      );
    });
  };

  const resultadosFormatados = formatResultados(resultados, nome, dataSorteio);

  const [currentApostaIndex, setCurrentApostaIndex] = useState(0);
  const [currentPremiadasIndex, setCurrentPremiadasIndex] = useState(0);
  const [verPremiados, setVerPremiados] = useState(false);

  useEffect(() => {
    if (!isModalVisible) {
      setCurrentApostaIndex(0);
      setCurrentPremiadasIndex(0);
      setVerPremiados(false);
    }
  }, [isModalVisible]);

  const navigateApostas = (direction) => {
    if (direction === 'prev' && currentApostaIndex > 0) {
      setCurrentApostaIndex(currentApostaIndex - 1);
    } else if (direction === 'next' && currentApostaIndex < apostas.length - 1) {
      setCurrentApostaIndex(currentApostaIndex + 1);
    }
  };

  const navigatePremiadas = (direction, premiadas) => {
    if (direction === 'prev' && currentPremiadasIndex > 0) {
      setCurrentPremiadasIndex(currentPremiadasIndex - 1);
    } else if (direction === 'next' && currentPremiadasIndex < premiadas.length - 1) {
      setCurrentPremiadasIndex(currentPremiadasIndex + 1);
    }
  };

  const getBackgroundColor = (numero) => {
    const isNumeroSorteado = numerosSorteados.some((item) => item.sorteados.split(' ').includes(numero.toString()));
    if (isNumeroSorteado) {
      const sorteioIndex = numerosSorteados.findIndex((item) => item.sorteados.split(' ').includes(numero.toString()));
      return sorteioIndex === 0 ? color : adjustColor(color, 99);
    }
    return 'transparent';
  };

  const getFontColor = (numero) => {
    const isNumeroSorteado = numerosSorteados.some((item) => item.sorteados.split(' ').includes(numero.toString()));
    if (isNumeroSorteado) {
      const sorteioIndex = numerosSorteados.findIndex((item) => item.sorteados.split(' ').includes(numero.toString()));
      return '#fff';
    }
    return color;
  };

  const premiadas = apostas.filter((aposta) => {
    const acertos = aposta.filter((item) => numerosSorteados.some((ns) => ns.sorteados.split(' ').includes(item.toString()))).length;
    const regraMinima = loteriaRegras[normalizeString(nome)];
    return regraMinima && regraMinima.includes(acertos);
  });

  return (
    <Modal
      title={
        <>
          <h1 className={styles.header} style={{ background: color }}>
            {nome}
            <a href={urlImpressao} target='_blank' className={styles.buttonPrint}>
              <MdPrint />
            </a>
          </h1>
        </>
      }
      open={isModalVisible}
      onOk={closeModal}
      onCancel={closeModal}
      width={900}
      footer=''
      className={styles.container}
    >
      <div className={styles.cardHeader} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h1 style={{ color: color }}>{valor}</h1>
        <div className={styles.divider}>
          <Divider style={{ background: color }} />
        </div>
        <div>
          <IoCalendarOutline size={30} className={styles.icon} style={{ backgroundColor: color }} />
          <p style={{ color: color }}>Sorteio {formatDate(dataSorteio)}</p>
          <HiOutlineTicket size={30} className={styles.icon} style={{ backgroundColor: color }} />
          <p style={{ color: color }}>Concurso {concurso}</p>
        </div>
      </div>

      {numerosSorteados && numerosSorteados.length > 0 && (
        <div className={styles.containerSorteados}>
          <h2 className={styles.titleSorteados} style={{ color: color, textAlign: 'center' }}>
            Sorteio Único:
          </h2>

          {numerosSorteados.map((item, index) => (
            <div key={index} className={styles.conteinerNumerosSorteados}>
              {numerosSorteados.length > 1 && <h2>Sorteio {index + 1}</h2>}
              <div>
                {item.sorteados.split(' ').map((numero) => (
                  <h1 key={numero} style={{ color: color, borderColor: color, borderRadius: 5, backgroundColor: adjustColor(color, 199) }}>
                    {numero}
                  </h1>
                ))}
              </div>
              {item.complemento && (
                <div>
                  <h1 style={{ color: color, borderColor: color, width: '100%' }}>{item.complemento}</h1>
                </div>
              )}
              {index < numerosSorteados.length - 1 && <hr className={styles.separator} />}
            </div>
          ))}
        </div>
      )}

      <div className={styles.card} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h2>
          <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
          Nossa Estratégia
        </h2>
        <div dangerouslySetInnerHTML={{ __html: regrasFormatadas[0] }} className={styles.regras} />
      </div>

      {regrasFormatadas[1] != '' && (
        <div className={styles.containerCard}>
          <div className={styles.card} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
            <h2>
              <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
              Nossas Matrizes
            </h2>
            <div dangerouslySetInnerHTML={{ __html: regrasFormatadas[1] }} className={styles.regras} />
          </div>
        </div>
      )}

      {(resultados && resultados.length > 0) || new Date(dataSorteio) <= new Date() ? (
        <>
          <div className={styles.containerResumoPremiacao} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
            <div className={styles.rowIcon}>
              <MdAttachMoney size={24} style={{ color: color }} />
              <h2 className={styles.titleSorteados} style={{ color: color }}>
                Resumo da premiação
              </h2>
            </div>
            <div className={styles.listaPremiacao}>{resultadosFormatados}</div>
            {verPremiados && (
              <div className={styles.apostasContainerPaiPremiacao}>
                <div className={styles.apostasContainer}>
                  <MdNavigateBefore
                    onClick={() => navigatePremiadas('prev', premiadas)}
                    className={styles.arrow}
                    style={{ visibility: currentPremiadasIndex > 0 ? 'visible' : 'hidden', backgroundColor: color, color: '#fff', marginRight: 5, borderRadius: 5 }}
                  />

                  {premiadas.length > 0 && premiadas[currentPremiadasIndex] ? (
                    <div className={styles.aposta} style={{ borderWidth: 1, borderColor: color }}>
                      <h3 style={{ color: color }}>
                        <BsTrophyFill color={color} />
                        Jogos premiados
                      </h3>
                      <Divider style={{ background: color, width: '90%', marginTop: 0 }} />
                      <h2 style={{ background: color, width: '100%' }}>Jogo {apostas.indexOf(premiadas[currentPremiadasIndex]) + 1}</h2> {/* Exibe o índice real do jogo */}
                      <div className={styles.numeros}>
                      {premiadas[currentPremiadasIndex].map((numero, index) => {
                          const isMonth = isNaN(numero);

                          return (
                            <div key={index} className={isMonth ? styles.mes : styles.numero} style={{ backgroundColor: getBackgroundColor(numero), color: getFontColor(numero), border: `1px solid ${color}` }}>
                              <h4 style={{ color: getFontColor(numero) }}>{numero}</h4>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.aposta}>
                      <h2 style={{ color: '#000' }}>Nenhuma aposta disponível</h2>
                    </div>
                  )}

                  <MdNavigateNext
                    onClick={() => navigatePremiadas('next', premiadas)}
                    className={styles.arrow}
                    style={{ visibility: currentPremiadasIndex < premiadas.length - 1 ? 'visible' : 'hidden', backgroundColor: color, color: '#fff', marginLeft: 5, borderRadius: 5 }}
                  />
                </div>
              </div>
            )}
            <Button className={styles.buttonComprar} onClick={() => setVerPremiados(!verPremiados)}>
              {verPremiados ? 'Esconder Premiados' : 'Ver premiados'}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
      {!numerosSorteados && (
        <div className={styles.pagamento} style={{ borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
          <h2>
            <BsLightbulbFill style={{ color: color, marginRight: '.5rem' }} />
            Pagamento
          </h2>
          <h4>Os Pagamentos das premiações serão lançados no SALDO de sua Conta, logo no primeiro dia útil após o sorteio.</h4>
          <p>Nossos bolões possuem alta chance de premiação e estão relacionados com estatísticas de cada loteria.</p>
        </div>
      )}

      <div className={styles.sucesso}>
        <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'>
          <path
            d='M9.66663 6.77297H11.3333V8.43964H9.66663V6.77297ZM9.66663 10.1063H11.3333V15.1063H9.66663V10.1063ZM10.5 2.60631C5.89996 2.60631 2.16663 6.33964 2.16663 10.9396C2.16663 15.5396 5.89996 19.273 10.5 19.273C15.1 19.273 18.8333 15.5396 18.8333 10.9396C18.8333 6.33964 15.1 2.60631 10.5 2.60631ZM10.5 17.6063C6.82496 17.6063 3.83329 14.6146 3.83329 10.9396C3.83329 7.26464 6.82496 4.27297 10.5 4.27297C14.175 4.27297 17.1666 7.26464 17.1666 10.9396C17.1666 14.6146 14.175 17.6063 10.5 17.6063Z'
            fill='#00897F'
          />
        </svg>
        <h1>Sucesso em seus jogos. Tribo da Sorte, sua aposta levada a sério.</h1>
      </div>
      <div className={styles.apostasContainerPai} style={{ borderWidth: 1, borderColor: color, backgroundColor: hexToRGBA(color, 0.1) }}>
        <h2 style={{ color: color }}>
          Cartões para Conferência de
          <br />
          Premiações.
        </h2>
        <Divider style={{ background: color, width: '90%' }} />
        <div className={styles.apostasContainer}>
          <MdNavigateBefore onClick={() => navigateApostas('prev')} className={styles.arrow} style={{ visibility: currentApostaIndex > 0 ? 'visible' : 'hidden', backgroundColor: color, color: '#fff', marginRight: 5, borderRadius: 5 }} />

          {apostas.length > 0 && apostas[currentApostaIndex] ? (
            <div className={styles.aposta} style={{ borderWidth: 1, borderColor: color }}>
              <h3 style={{ color: color }}>Total de {apostas.length} apostas:</h3>
              <Divider style={{ background: color, width: '90%', marginTop: 0 }} />

              <h2 style={{ background: color, width: '100%' }}>Jogo {currentApostaIndex + 1}</h2>
              <div className={styles.numerosContainer}>
                <div className={styles.numeros}>
                  {apostas[currentApostaIndex].map((numero, index) => {
                    const isMonth = isNaN(numero);

                    return (
                      <div 
                        key={index} 
                        className={isMonth ? styles.mes : styles.numero} 
                        style={{ backgroundColor: getBackgroundColor(numero), color: getFontColor(numero), border: `1px solid ${color}` }}
                      >
                        <h4 style={{ color: getFontColor(numero) }}>{numero}</h4>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.aposta}>
              <h2>Nenhuma aposta disponível</h2>
            </div>
          )}

          <MdNavigateNext
            onClick={() => navigateApostas('next')}
            className={styles.arrow}
            style={{ visibility: currentApostaIndex < apostas.length - 1 ? 'visible' : 'hidden', backgroundColor: color, color: '#fff', marginLeft: 5, borderRadius: 5 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalVerBoloesPrint;
