import React, { useMemo, memo, useState } from 'react';
import { Modal, Spin, Switch } from 'antd';
import styles from './style.module.scss';
import { GoTrophy } from 'react-icons/go';

const VerApostas = ({ apostas, isModalVisible, closeModal, numerosSorteados, concursoSelecionado, grupo, bolaoName }) => {
  const [verApenasSorteados, setVerApenasSorteados] = useState(false);

  const numerosSorteadosArray = useMemo(() => {
    if (!numerosSorteados || numerosSorteados.length === 0) return [];
    return numerosSorteados.flatMap((item) => item.sorteados.split(' ').map((num) => parseInt(num, 10)));
  }, [numerosSorteados]);

  const loteriaRegras = {
    lotofacil: [11, 12, 13, 14, 15],
    lotomania: [0, 15, 16, 17, 18, 19, 20],
    duplasena: [3, 4, 5, 6],
    supersete: [3, 4, 5, 6, 7],
    megasena: [4, 5, 6],
    quina: [2, 3, 4, 5],
    quinasaojoao: [2, 3, 4, 5],
  };

  const normalizeString = (str) => {
    if (!str) return '';
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '')
      .replace(/-/g, '')
      .toLowerCase();
  };

  const coresTitulo = {
    megasena: '#29705e',
    megasenadavirada: '#29705e',
    lotofacil: '#9915ba',
    quina: '#06387c',
    quinasaojoao: '#06387c',
    lotomania: '#f78100',
    timemania: '#01d001',
    duplasena: '#a61324',
    duplasenadepascoa: '#a61324',
  };

  const adjustColor = (color, amount) => {
    if(!color){
      color = '#29705e'
    }
    let usePound = false;
    if (color[0] === '#') {
      color = color.slice(1);
      usePound = true;
    }

    let num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let b = ((num >> 8) & 0x00ff) + amount;
    let g = (num & 0x0000ff) + amount;

    r = Math.min(255, Math.max(0, r));
    b = Math.min(255, Math.max(0, b));
    g = Math.min(255, Math.max(0, g));

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  };

  const getApostaColor = (nome, index = 0, sorteioIndex = 0) => {
    const normalizedNome = normalizeString(nome);
    const baseColor = coresTitulo[normalizedNome] || '#00897f';
    const colorAdjust = adjustColor(baseColor, index * 78 + sorteioIndex * 50 );
    return colorAdjust;
  };

  const getApostaColorBase = (nome, index = 0, sorteioIndex = 0) => {
    const normalizedNome = normalizeString(nome);
    const baseColor = coresTitulo[normalizedNome] || '#00897f';
    return baseColor;
  };

  const ApostaItem = memo(({ numero, color, isSorteado, index, sorteioIndex }) => {
    let isSorteadoDois = false;

    if (numerosSorteados[1]) {
      const numerosSorteadosDois = numerosSorteados[1]['sorteados'].split(' ');
      isSorteadoDois = numerosSorteadosDois.includes(numero.toString());
    }

    return (
      <div className={styles.apostaItem}>
        <div
          style={{ borderColor: getApostaColorBase(bolaoName), background: isSorteado || isSorteadoDois ? (isSorteadoDois ? getApostaColor(bolaoName, 2, sorteioIndex) : getApostaColorBase(bolaoName, 1, sorteioIndex)) : '' }}
          className={styles.apostasNumero}
        >
          <h2 style={{ color: isSorteado ? '#fff' : getApostaColorBase(bolaoName) }}>{numero}</h2>
        </div>
      </div>
    );
  });

  const renderAposta = (aposta, index) => {
    if (!aposta) return null;
    const color = getApostaColor(bolaoName);
    return (
      <div key={index} className={styles.jogos}>
        <div className={styles.jogosArea}>
          {aposta &&
            aposta.apostas &&
            aposta.apostas.map((linha, idx) => {
              const acertos = linha.filter((item) => numerosSorteadosArray.includes(parseInt(item, 10))).length;
              const loteriaNome = normalizeString(bolaoName);
              const regraMinima = loteriaRegras[loteriaNome];

              if (verApenasSorteados && (!regraMinima || !regraMinima.includes(acertos))) return null;

              return (
                <div className={styles.jogosValores} key={`${index}-${idx}`}>
                  <h4 className={styles.titleJogos} style={{ background: getApostaColorBase(bolaoName), color: '#fff', padding: '8px' }}>
                    {verApenasSorteados && <GoTrophy style={{ marginRight: '.5rem' }} />}
                    {idx + 1}
                  </h4>
                  <div className={styles.row}>
                    {linha.map((item) => (
                      <div key={item} className={styles.col}>
                        <ApostaItem numero={item} color={color} index={index} sorteioIndex={index} isSorteado={numerosSorteadosArray.includes(parseInt(item, 10))} />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          {aposta && aposta.numeros && (
            <div className={styles.row}>
              <span>Prêmio {index + 1}</span>
              {aposta.numeros.map((numero, idx) => (
                <div key={idx} className={styles.col}>
                  <ApostaItem numero={numero} color={color} isSorteado={numerosSorteadosArray.includes(parseInt(numero, 10))} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const filteredApostas = useMemo(
    () =>
      Array.isArray(apostas)
        ? apostas
            .filter((aposta) => aposta.concurso === concursoSelecionado && aposta.grupo === grupo)
            .filter((aposta) => {
              if (!verApenasSorteados) return true;
              const loteriaNome = normalizeString(bolaoName);
              const regraMinima = loteriaRegras[loteriaNome];
              return aposta.apostas.some((linha) => {
                const acertos = linha.filter((numero) => numerosSorteadosArray.includes(parseInt(numero, 10))).length;
                return regraMinima && regraMinima.includes(acertos);
              });
            })
        : [],
    [apostas, concursoSelecionado, grupo, verApenasSorteados, numerosSorteadosArray, bolaoName]
  );

  return (
    <Modal
      title={
        <h1 className={styles.header} style={{ background: getApostaColorBase(bolaoName) }}>
          JOGOS
        </h1>
      }
      open={isModalVisible}
      onOk={closeModal}
      onCancel={closeModal}
      width={900}
      footer={null}
      className={styles.container}
    >
      {numerosSorteados && numerosSorteados.length > 0 && (
        <div className={styles.checkBoxArea} style={{ background: getApostaColorBase(bolaoName)}}>
          <h1 style={{ color: '#fff', marginRight: 8 }}>Ver apenas sorteados</h1>
          <Switch checked={verApenasSorteados} onChange={() => setVerApenasSorteados(!verApenasSorteados)} />
        </div>
      )}
      {numerosSorteados && numerosSorteados.length > 0 && (
        <>
          <h2 className={styles.titleSorteados} style={{ color: getApostaColor(bolaoName)}}>
            Números Sorteados
          </h2>
          {numerosSorteados.map((item, index) => (
            <div key={index} className={styles.conteinerNumerosSorteados}>
              <h2>Sorteio {index + 1}</h2>
              <div>
                {item.sorteados.split(' ').map((numero) => (
                  <h1 key={numero} style={{ color: '#fff', background: getApostaColorBase(bolaoName, index, index), borderColor: getApostaColor(bolaoName, index, index) }}>
                    {numero}
                  </h1>
                ))}
              </div>
              {index < numerosSorteados.length - 1 && <hr className={styles.separator} />}
            </div>
          ))}
        </>
      )}
      {apostas && !apostas.length ? <Spin /> : <div className={styles.list}>{renderAposta(filteredApostas[0], 0)}</div>}
    </Modal>
  );
};

export default memo(VerApostas);
